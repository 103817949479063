import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as taxiTripAPI from 'api/taxiTrip';

import './Form.scss';

function Form(props) {
  const { t } = useTranslation();
  const [alert, setAlert] = useState(false);
  const [boxAlert, setBoxAlert] = useState(false);
  const noteRef = useRef(null);
  const phoneRef = useRef(null);

  window.addEventListener('popstate', function () {
    if (window.localStorage.getItem('pageStatus') === 'main') {
      noteRef.current.blur();
      phoneRef.current.blur();
    }
  });

  useEffect(() => {
    setDefaultCreditCard();
  }, []);

  useEffect(() => {
    if (props.callTaxifail !== '') {
      setAlert(true);
      isTel(props.orderDetail.phone) ? setBoxAlert(false) : setBoxAlert(true);
    }
  }, [props.callTaxifail, props.orderDetail.phone]);

  function isTel(str) {
    let reg2 = /(^09\d{2}-?\d{3}-?\d{3}$)|(^((\+886)|(886))\s?(9)\d{2}\s?\d{3}\s?\d{3}$)/;
    if (str.length < 10 || str.length > 18) {
      return false;
    } else {
      return reg2.exec(str);
    }
  }

  function inputOnChange(e) {
    let input = e.target.value;
    props.setOrderDetail((orderDetail) => ({
      ...orderDetail,
      phone: input,
    }));
    if (isTel(input)) {
      props.setOrderDetail((orderDetail) => ({
        ...orderDetail,
        ok: true,
        errMsg: '',
      }));
      alert && setBoxAlert(false);
    } else {
      props.setOrderDetail((orderDetail) => ({
        ...orderDetail,
        ok: false,
        errMsg: input ? 'type' : 'empty',
      }));
      alert && setBoxAlert(true);
    }
  }

  function textareaOnChange(e) {
    let input = e.target.value;
    props.setOrderDetail((orderDetail) => ({
      ...orderDetail,
      comment: input,
    }));
  }

  const handleOnChange = useCallback(
    (key, index) => (e) => {
      let items = [];
      let optionKey = key;
      if (key === 'paymentTypeList') {
        items = [...props.orderDetail[key]];
        items.forEach((item, i) => {
          item.isChecked = i === index;
        });
      } else if (key === 'need') {
        optionKey = props.isOnlinePay ? 'uberOptions' : 'need';
        items = [...props.orderDetail[optionKey]];
        items[index].isChecked = e.target.checked;
      }
      props.setOrderDetail((pre) => ({ ...pre, [optionKey]: items }));
    },
    [props]
  );

  /**
   * Formats the card type based on the given cardType.
   *
   * @param {string} cardType - The card type to be formatted.
   * @returns {string} The formatted card type.
   *
   * @see {@link https://docs.tappaysdk.com/tutorial/zh/advanced.html#bind-card-api}
   */
  const cardTypeMap = {
    VISA: 'VISA',
    MasterCard: 'Mastercard',
    JCB: 'JCB',
    AMEX: 'Amex',
    'Union Pay': 'UnionPay',
  };

  function formatCardType(cardType) {
    return cardTypeMap[cardType] || '';
  }

  function setDefaultCreditCard() {
    taxiTripAPI
      .GetUserBindCard()
      .then(([res]) => {
        if (res) {
          const cards = res.data.data.cards;
          const formatedCreditCards = cards.map((card) => ({
            ...card,
            id: card.guid,
            type: formatCardType(card.cardAssociation),
            alt: card.cardAssociation,
            radioButtonText: '預設',
            encryptedCardNumber: `****${card.lastFour}`,
            bank: card.issueBank,
          }));

          if (formatedCreditCards.length > 0) {
            const defaultCardIndex = formatedCreditCards.findIndex((card) => card.isDefault);
            if (defaultCardIndex > -1) {
              props.setSelectedCard(formatedCreditCards[defaultCardIndex]);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const listToShow = props.isOnlinePay ? props.orderDetail.uberOptions : props.orderDetail.need;

  return (
    <div className={`form ${props.formDisplay ? 'inActive' : 'active'}`}>
      <div className="form__body">
        <div>
          <div className="formContainer">
            <span>
              {t('A021')}
              <span style={{ color: '#F05151', fontSize: '1.6rem' }}>*</span>
            </span>
            <div
              className="formContainer__box"
              style={boxAlert ? { border: '1px solid #f05151' } : null}
            >
              <input
                ref={phoneRef}
                type="tel"
                pattern="[0]{1}[0-9]{9}"
                maxLength="20"
                required
                placeholder={t('A022')}
                value={props.orderDetail ? props.orderDetail.phone : ''}
                onChange={(e) => inputOnChange(e)}
              />
            </div>
          </div>
          {props.isOnlinePay && (
            <div className="formContainer">
              <div className="formContainer__title-container">
                <span>{t('A081')}</span>
              </div>
              <div className="formContainer__sp formContainer__sp--column">
                {props.orderDetail.paymentTypeList.map((item, index) => (
                  <RadioButton
                    checked={item.isChecked}
                    onChange={handleOnChange('paymentTypeList', index)}
                    text={item.name}
                    id={item.id}
                    name={index}
                    key={index}
                    selectedCard={props.selectedCard}
                    setEstimateFareTimeoutControl={props.setEstimateFareTimeoutControl}
                    setPaymentOriginPage={props.setPaymentOriginPage}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="formContainer">
            <div className="formContainer__title-container">
              <span>{t('A023')}</span>
              <span className="formContainer__subtitle">{t('A083')}</span>
            </div>
            <div className="formContainer__sp">
              {listToShow.map((item, index) => (
                <CheckBox
                  checked={item.isChecked}
                  onChange={handleOnChange('need', index)}
                  text={item.name}
                  id={item.id}
                  name={index}
                  key={index}
                />
              ))}
            </div>
          </div>
          <div className="formContainer high-block">
            <span>{t('A027')}</span>
            <div className="formContainer__textarea">
              <textarea
                ref={noteRef}
                placeholder={t('A028')}
                onChange={textareaOnChange}
                value={props.orderDetail ? props.orderDetail.comment : ''}
                maxLength="50"
              />
            </div>
          </div>
          <div className="formContainer reminder">
            <span>
              溫馨提醒：
              <br />
              一、透過智生活叫車以示同意服務
              <span
                style={{
                  color: 'var(--primaryColor)',
                  textDecoration: 'underline',
                  margin: 0,
                  fontWeight: 400,
                  fontSize: '1.5rem',
                }}
                onClick={() => props.toggleUserAgreementModal()}
              >
                使用條款
              </span>
              <br />
              {!props.isOnlinePay && '二、如需車資收據請於下車時向司機提出'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;

const CheckBox = ({ checked, onChange, text, name, id }) => {
  return (
    <div className="formContainer__sp__checkbox">
      <input
        onChange={onChange}
        id={id}
        type="checkbox"
        checked={checked}
        name={name}
        autoComplete="off"
      />
      <label htmlFor={id}>{text}</label>
    </div>
  );
};

const RadioButton = ({
  checked,
  onChange,
  text,
  name,
  id,
  selectedCard,
  setEstimateFareTimeoutControl,
  setPaymentOriginPage,
}) => {
  const history = useHistory();
  const handleCardSelectorRedirect = () => {
    setEstimateFareTimeoutControl({ action: 'stop' });
    setPaymentOriginPage('selectCreditCard');
    const initLocation = {
      pathname: '/SelectCreditCard',
      state: {
        initMainPage: true,
      },
    };
    history.replace(initLocation);
  };

  const issueBankText = useMemo(() => {
    // 超過20個字元時，只顯示前20個字元
    if (!selectedCard) return '';
    return selectedCard.issueBank.length > 20
      ? selectedCard.issueBank.slice(0, 20)
      : selectedCard.issueBank;
  }, [selectedCard]);

  return (
    <div className="formContainer__sp__radio">
      <input
        onChange={onChange}
        id={id}
        type="radio"
        checked={checked}
        name={name}
        autoComplete="off"
      />
      <label htmlFor={id}>{text}</label>
      {Number(id) === 1 ? (
        <button
          className="formContainer__sp__radio__btn"
          onClick={() => handleCardSelectorRedirect()}
        >
          <span>
            {selectedCard ? issueBankText + selectedCard.encryptedCardNumber : '前往綁定'}
          </span>
          <i className="icon-arrow-right"></i>
        </button>
      ) : null}
    </div>
  );
};
